import * as React from "react";
import Routes from "routes/routes";

const App: React.FC = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default App;
